<template>
    <div>
   
         <section class='search-filter'>
                <div class="container-fluid px-0">
                    <div class="search-filter-wrapper">
                        <div class="row">
                            <div class="col-lg-12">
                                <form>
                                    <div class="search-filter-form-wrapper">
                                        <div class="filter-name-address">
                                            <div class="filter-icon-holder">
                                                <img src="@/assets/img/icons/zoom.png">
                                            </div>
                                            <div class="filter-holder-input">
                                                <input type="text" class="form-control" v-model="form.location"
                                                    placeholder="Name, State, City...">
                                            </div>
                                        </div>

                                        <div class="filter-property-type">
                                            <div class="filter-icon-holder">
                                                <img src="@/assets/img/icons/union.png">
                                            </div>
                                            <div class="filter-holder-input">
                                                
                                                <select class="form-control" aria-describedby="All Home Types" v-model="form.property_type">
                                                        <option value="">All Home Types</option>
                                                        <option :value="i.name" v-for="i in property_type_list" :key="i.id">{{i.name}}</option>
                                                    </select>
                                            </div>
                                        </div>

                                        <div class="filter-maximum-price">
                                            <div class="filter-icon-holder">
                                                <img src="@/assets/img/icons/naira-icon.png">
                                            </div>
                                            <div class="filter-holder-input">
                                                <input type="text" class="form-control" placeholder="Maximum price" v-model="price">
                                            </div>
                                        </div>

                                        <div class="filter-search-btn">
                                            <button type="submit" @click.prevent="searchPro"  class="search-filter-btn">Search</button>
                                        </div>
                                    </div>
                                </form>



                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
</template>
<script>
import {FormMixin} from '@/mixins/form'
import {AppMixin} from '@/mixins/AppMixin'
export default {
    name:"SearchProperties",
    mixins:[FormMixin,AppMixin],
        data(){
        return{
            form:{
                location:'',
                bedrooms:'',
                bathrooms:'',
                price:'',
                property_type:''
            }
        }
    },
    computed:{
        price:{
             get() {
              let r = this.formatToCommaSeperated(this.form.price
              );
              return r;
            },
            set(val) {
              this.form.price=val;

            }
        }
    },
    methods:{
       
        searchPro(){
             if(this.form.location != ''|| this.form.property_type!=''|| this.form.price != ''){
                 this.$store.dispatch("searchPropertiesAction",this.form).then((r)=>{
                     this.$router.push({name:'Properties',params:{type:'search'}})
                 });
             }
            
        }
    }
}
</script>
<style scoped>


</style>