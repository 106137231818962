import {
    isLoggedIn
} from '@/router/middlewares/guards.js';
import multiguard from 'vue-router-multiguard';
import Home from './views/Home.vue'
import Page404 from './views/Page404.vue'
const general_routes = [
     {
         path: '/',
         name: 'Home',
         component: Home,
         meta: {
             layout: 'frontend',
             title:'Housing Fund'
         }
     },
     {
         path: '/login',
         name: 'Login',
         component: () => import( /* webpackChunkName: "pages" */ './views/Signin.vue'),
         meta: {
             layout: 'frontend',
             title:'Login'
         }
     },
     {
         path: '/sign-up',
         name: 'Register',
        component: () => import( /* webpackChunkName: "pages" */ './views/Signup.vue'),
         meta: {
             layout: 'frontend',
             title:'Sign Up'
         }
     },
     {
         path: '/forgot-password',
         name: 'Forgot',
         component: () => import( /* webpackChunkName: "pages" */ './views/Forgot.vue'),
         meta: {
             layout: 'frontend',
             title:'Forgot Password'
         }
     },
    {
        path: '/mortgage',
        name: 'Mortgage',
        component: () => import( /* webpackChunkName: "pages" */ './views/Mortgage.vue'),
        meta: {
            layout: 'frontend',
            title:'Mortgage'
        }
    }, {
        path: '/contact-us',
        name: 'ContactUs',
        component: () => import( /* webpackChunkName: "pages" */ './views/ContactUs.vue'),
        meta: {
            layout: 'frontend',
            title:'Contact Us'
        }
    }, {
        path: '/faq',
        name: 'FAQ',
        component: () => import( /* webpackChunkName: "pages" */ './views/Faq.vue'),
        meta: {
            layout: 'frontend',
            title:'FAQ'
        }
    },
    {
        path: '*',
        name: 'page404',
        meta: {
            layout: 'frontend',
            title:'Not Found'

            // public: true,
            // onlylogout: true
        },
        component: Page404
    },
];
export default general_routes;