<template>
    <div>
         
        <section class="mortgage-rate-news-ticker">
                <div class="container-fluid px-0">
                    <div class="mortgage-rate-news-ticker-wrapper">
                        <div class="mortgage-rate-news-ltr">
                            <p>Mortgage Rates</p>
                        </div>
                        <div class="mortgage-rate-news-rtl">
                            <div class="mortgage-rate-news-rtl-content">
                                <div class="mortgage-rate-news-rtl-content-scroll">
                                    <span v-for="lender in lender_rates" :key="lender.id">{{lender.client_name}} : {{lender.finance_rate}}%</span>
                                    <span>Coop Mortgage Bank Ltd</span>
                                    <span>Brent Mortgage Bank</span>
                                    <span>Aso Savings &amp; Loans Plc</span>
                                    <span>Abbey Mortgage Bank</span>
                                    <span>Imperial Homes Mortgage Bank</span> 
                                    <!-- <span>New Prudential Mortgage Bank</span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
</template>
<script>
export default {
    name:"Marquee",
    computed:{
        lender_rates(){
            let t = this.$store.state.general_module.lender_rates;
            return t;
        }
    }
}
</script>
<style scoped>
   
</style>