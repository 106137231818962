<template>
    <div>
          <section class="error-404">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-10 col-md-8 col-lg-6 mx-auto">
                            <div class="error-404-wrapper">
                                <span>40<span>4</span></span>
                                <h2>Something went wrong!</h2>
                                <p>Pellentesque ultrices orci id justo vehicula, non aliquam erat lacinia. Mauris
                                    rhoncus venenatis tempor.</p>
                                <a href="/index.html" class="cta-btn">Back To Home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="error-404-quick-fix">
                <div class="error-404-suggestion-bar">
                    <div class="container">
                        <p><strong><i class="fa fa-plus"></i>Qucik Links</strong> - Explore popular categories</p>
                    </div>
                </div>
            </section>
    </div>
</template>
<script>

export default {
    name:"Page404"
    
}
</script>