<template>
    <div >
         <!-- <ValidationObserver ref="form" v-slot="{ invalid }"> -->
        <form>
            <div class="form-wrapper step-one-form">
                <div class="form-group row" >
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                        <label class="form-control-label" >Net Monthly Income<span
                                class="text-danger">*</span></label>
                        <div class="input-group input-group-merge">
                            <div class="input-group-prepend"><span
                                    class="input-group-text">₦</span></div>
                                    <input type="text" v-model="monthly_income" 
                                 class="form-control " :class="{ 'is-invalid': submitted && $v.form.monthly_net_pay.$error }"
                                placeholder="What is your Net Monthly Income">
                        </div>
                         <!-- <div v-if="submitted && !$v.form.monthly_net_pay.required" class="invalid-feedback">First Name is required</div> -->
                    </div>
                      <div v-if="submitted && !$v.form.monthly_net_pay.required" class="form-error">Monthly income is required</div> 
                </div>
                <div class="form-group row"  :class="{ 'is-invalid': submitted && $v.form.have_additional.$error }" >
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <legend class="form-control-label">Do you have Additional Income?<span
                                class="text-danger">*</span></legend>
                        <div class="form-checkbox row">
                            <div class="col-lg-6"><label><input type="radio" 
                                         v-model="form.have_additional" value="1" checked=""><span> Yes</span></label></div>
                            <div class="col-lg-6"><label><input type="radio"
                                        v-model="form.have_additional" value="0"><span>No</span></label></div>
                        </div>
                          <div v-if="submitted && !$v.form.have_additional.required" class="form-error">Select an option</div> 
                    </div>
                </div>
                <div class="form-group row" v-show="form.have_additional==1">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"><label
                            class="form-control-label">Net Monthly Additional Income<span
                                class="text-danger">*</span></label>
                        <div class="input-group input-group-merge">
                            <div class="input-group-prepend"><span
                                    class="input-group-text">₦</span></div><input type="text"
                                 class="form-control " v-model="additional_income"
                                 :class="{ 'is-invalid': submitted && $v.form.additional_income.$error }"
                                placeholder="How much Additional Income do you earn Monthly">
                        </div>
                    </div>
                      <div v-if="submitted && !$v.form.additional_income.required" class="form-error">Additional income is required</div> 
                </div>
                <div class="form-group row"  :class="{ 'is-invalid': submitted && $v.form.have_existing_obligation.$error }">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <legend class="form-control-label">Do you have other Loan
                            obligations?<span class="text-danger">*</span></legend>
                        <div class="form-checkbox row">
                            <div class="col-lg-6"><label><input type="radio"
                                        v-model="form.have_existing_obligation"  value="1"><span> Yes </span></label></div>
                            <div class="col-lg-6"><label><input type="radio"
                                        v-model="form.have_existing_obligation"  value="0" checked=""><span> No </span></label></div>
                        </div>
                    </div>
                        <div v-if="submitted && !$v.form.have_existing_obligation.required" class="form-error">Select an option</div> 
                </div>
                <div class="form-group row" v-show="form.have_existing_obligation==1">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"><label
                            class="form-control-label">Monthly Loan Repayment<span
                                class="text-danger">*</span></label>
                        <div class="input-group input-group-merge">
                            <div class="input-group-prepend"><span
                                    class="input-group-text">₦</span></div><input type="text"
                                v-model="outstanding_loans" class="form-control "
                                 :class="{ 'is-invalid': submitted && $v.form.outstanding_loans.$error }"
                                placeholder="Currently Monthly Loan Repayments (If Any)">
                        </div>
                    </div>
                    <div v-if="submitted && !$v.form.outstanding_loans.required" class="form-error">Outstanding Loan is required</div> 
                </div>
                
                <div class="form-group row" >
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <legend class="form-control-label">Are you borrowing with a
                            Partner?<span class="text-danger">*</span></legend>
                        <div class="form-checkbox row">
                            <div class="col-lg-6"><label><input type="radio" v-model="form.have_co_borrower"
                                        value="1"><span> Yes</span></label></div>
                            <div class="col-lg-6"><label><input type="radio" v-model="form.have_co_borrower"
                                        value="0" checked=""><span> No</span></label>
                            </div>
                        </div>
                         <div v-if="submitted && !$v.form.have_co_borrower.required" class="form-error">Select an option</div> 
                    </div>
                </div>
                  <div class="form-group row" v-show="form.have_co_borrower==1">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"><label
                            class="form-control-label">Partner’s Net Monthly Income?<span
                                class="text-danger">*</span></label>
                        <div class="input-group input-group-merge">
                            <div class="input-group-prepend"><span
                                    class="input-group-text">₦</span></div><input type="text"
                                v-model="co_borrower" class="form-control "
                                  :class="{ 'is-invalid': submitted && $v.form.co_borrower_monthly_income.$error }"
                                placeholder="What is your Current Partner’s Net Monthly Income?">
                        </div>
                    </div>
                       <div v-if="submitted && !$v.form.co_borrower_monthly_income.required" class="form-error">Partner Monthly income is required</div> 
                </div>
               <div class="form-group row" >
                 
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label
                            class="form-control-label">Date of Birth<span
                                class="text-danger">*</span></label>
                        <div class="input-group input-group-merge">
                            <div class="input-group-prepend">
                                <span class='input-group-text'>
                                                            <i class="fa fa-calendar"></i>
                                                        </span>
                            </div>
                              
                                    <input type="date" :class="{ 'is-invalid': submitted && $v.form.dob.$error }"
                                v-model="form.dob" class="form-control " placeholder="Date of Birth">
                                 
                        </div>
                        <div v-if="submitted && !$v.form.dob.required" class="form-error">Date of birth is required</div>
                        <div v-if="submitted && !$v.form.dob.too_young" class="form-error">You are too young</div>
                    </div>
                     
                    
                </div>

                <div class="form-group row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"><label
                            class="form-control-label">How long do you want this Loan for?<span
                                class="text-danger">*</span></label>
                                <range-slider class="slider" :min="min_range" :max="max_range" step="1" v-model="form.loan_tenure">
                                    <template slot="knob">
                                        <div class="knobby">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="40" height="40">
                                            <path
                                            fill="#CDD8C3"
                                            fill-rule="evenodd"
                                            d="M9.864 20c5.243-5.195 7.864-9.24 7.864-12.136A7.863 7.863 0 009.864 0 7.863 7.863 0 002 7.864C2 10.759 4.621 14.804 9.864 20z"
                                            ></path>
                                            <text x="10" y="10" fill="currentColor" text-anchor="middle">{{ form.loan_tenure + ' yrs' }}</text>
                                        </svg>
                                        </div>
                                    </template>
                                </range-slider>
                                <div class="range-value d-flex justify-content-between">
                                        <span>{{min}} </span>
                                        <span>{{max}} </span>
                                </div>
                              <!-- <div class="range-div">
                                    <input type="range" ref="range"  v-model="form.loan_tenure" class="form-range" :min="min_range" :max="max_range" :class="{ 'is-invalid': submitted && $v.form.loan_tenure.$error }" >
                                    
                                    <output class="bubble" ref="bubble">{{form.loan_tenure}} {{resultType}}</output>
                                   
                                
                                <div class="range-value d-flex justify-content-between">
                                    <span>{{min}} </span>
                                    <span>{{max}} </span>
                                </div> -->
                                  <div v-if="submitted && !$v.form.loan_tenure.required" class="form-error">Partner Monthly income is required</div> 
                        </div>
                    <!-- </div> -->
                </div>

                 <!-- <vue-slide-bar
                  v-model="form.loan_tenure"
                  :min="min_range"
                  :max="max_range"
                  draggable="true"
                  :processStyle="sliderCustomzie.processStyle"
                  :lineHeight="sliderCustomzie.lineHeight"
                  :tooltipStyles="sliderCustomzie.tooltipStyles">
        </vue-slide-bar> -->
                <div class="form-group row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"><label
                            class="form-control-label">Location<span
                                class="text-danger">*</span></label>
                        <div class="input-group input-group-merge">
                            <div class="input-group-prepend">  <span class='input-group-text'>
                                                            <i class="fa fa-map-marker"></i>
                                                        </span></div>
                                    <input type="text"  
                                v-model="form.location" class="form-control "
                                placeholder="Where is your preferred Location (Eg. Lagos, Abuja)">
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="offset-lg-3 col-lg-6">
                    <slot name="button"></slot>
                
                </div>
            </div>
        </form>
        
        <!-- </ValidationObserver> -->
      
    </div>
</template>
<script>
import Vue from 'vue';
// import RangeSlider from '../form/RangeSlider.vue';
import EventBus from '@/services/event'
import {FormMixin} from '@/mixins/form'/*  */
import {CalculatorMixin} from '@/mixins/CalculatorMixin'/*  */
import { required, email, minLength, sameAs,requiredIf,numeric } from "vuelidate/lib/validators";
import RangeSlider from "vue-range-slider";
import "vue-range-slider/dist/vue-range-slider.css";
const too_young = (value, vm) =>{
    let today = new Date();
    let birthday = new Date(value);
    let age = today.getFullYear() - birthday.getFullYear();
    console.log('age: '+age)
    if(age<=21){
        return false;
    }
    return true;
};
export default {
  components: { RangeSlider },
  mixins:[FormMixin,CalculatorMixin],
    name:"AffordabilityForm",
    props:{
        inputBg:{type:String,default:'#fff'},
        formType:{type:Boolean,default:false}
    },
    data(){
        return{
          min_range: 1,
          max_range: 30,
          submitted:false,
            form:{
                monthly_net_pay:'',
                have_additional:'',
                additional_income:'',
                have_existing_obligation:'',
                outstanding_loans:'',
                have_co_borrower:'',
                co_borrower_monthly_income:'',
                dob:'',
                loan_tenure:'5',
                location:'',
                age:''
            },
         
          
        }
    },
        validations: {
            form: {
                monthly_net_pay: { required},
                dob: { required,too_young },
                have_additional: { required },
                have_existing_obligation: { required },
                have_co_borrower: { required },
                loan_tenure: { required },
                additional_income: { required: requiredIf((form) =>form.have_additional==1)},
                outstanding_loans: { required: requiredIf((form) =>form.have_existing_obligation==1)},
                co_borrower_monthly_income: { required: requiredIf((form) =>form.have_co_borrower==1)},
                // email: { required, email },
                // password: { required, minLength: minLength(6) },
                // confirmPassword: { required, sameAsPassword: sameAs('password') }
            }
        },
    watch:{
        'form.monthly_net_pay':function(val){
           const result = this.formatToCommaSeperated(val);
          //  Vue.nextTick(() => (this.strin.monthly_net_income = result));
        },
        'form.dob':function(v){
         this.form.age = this.calculateAge(v);
        this.max_range =  this.calculateMaxTenure(this.form.age);
        // this.setBubble()
        this.form.loan_tenure = this.max_range;
        
       

        },
        'form.loan_tenure':function(val){
            // this.setBubble()
        },
    },
    computed:{
       
       monthly_income:{
            get() {
              let r = this.formatToCommaSeperated(this.form.monthly_net_pay);
              return r;
            },
            set(val) {
              this.form.monthly_net_pay=val;

            }
       },
       additional_income:{
            get() {
              let r = this.formatToCommaSeperated(this.form.additional_income);
              // console.log(r);
              return r;
            },
            set(val) {
              this.form.additional_income=val;

            }
       },
       outstanding_loans:{
            get() {
              let r = this.formatToCommaSeperated(this.form.outstanding_loans);
              // console.log(r);
              return r;
            },
            set(val) {
              this.form.outstanding_loans=val;

            }
       },
       co_borrower:{
            get() {
              let r = this.formatToCommaSeperated(this.form.co_borrower_monthly_income);
              // console.log(r);
              return r;
            },
            set(val) {
              this.form.co_borrower_monthly_income=val;

            }
       },
 
     
    },
    methods:{
        
      submitFromModal(){
          this.submitted=true
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
                this.$store.dispatch("saveAffordabilityFormAction",this.form)
        this.$store.dispatch("calculateAffordabilityAction",this.form).then((r)=>{
          this.processStepFunction(true,false);
          EventBus.$emit('open-affordability-modal',false);
           
          if(this.$route.name != 'FilterProperties'){
              this.$router.push({name:'FilterProperties'});
          }
          });
         
      },
  //     
      submitAffordability(){
            this.submitted=true
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
           this.$store.dispatch("saveAffordabilityFormAction",this.form)
          this.$store.dispatch("calculateAffordabilityAction",this.form).then((r)=>{
             this.processStepFunction(true,false);
           EventBus.$emit('open-affordability-modal',false);
            this.$store.dispatch("formStepAction",2);
          });
        
        
        
         
        },
        // getUserAffordability(){
        //   // let data = {monthly_net_pay:this.form.monthly_net_pay,age:this.form.age,dob:this.form.dob,}
        //   this.$store.dispatch("calculateAffordabilityAction",this.form).then((r)=>{

        //   });
        // }
    },
    created(){
        // this.setBubble();
        EventBus.$on('submitAffordability',(t)=>{
          this.submitAffordability();
        })
        EventBus.$on('submitModalAffordability',(t)=>{
          this.submitFromModal();
        })
    }
}
</script>
<style scoped>
  .slider {
  width: 100%;
  margin-top: 3rem;
}
@media (max-width:1000px){
    .form-wrapper .form-control-label, .form-wrapper legend {
    /* font-size: 11px; */
    margin-top: 0px;
    text-align: left;
    margin-bottom: 0;
    }
    .form-wrapper .form-control-label {
    background: transparent;
    position:relative;
    left:2%;
    }
  .form-wrapper{
      /* padding: 1rem 1rem; */
  }
}
</style>