import {
    TokenService
} from "@/services/TokenService";
import UserService from "@/services/UserService";
import router from "@/router/index"
import {
    defaulturl
} from '@/services/UrlSetting';
import {
    ApiSource
}
from '@/services/ApiService'
const api = new ApiSource(defaulturl);

const state = {
    calculating: false,
    selectedProperty: '',
    list_style: 'all',
    affordable_properties: [],
    properties: [],
    featured_properties: [],
    recent_properties: [],
    below_price_properties: [],
    property: ''

}
const mutations = {
    // updateField,
    LIST_STYLE(state, payload) {
        state.list_style = payload
    },
    AFFORDABILITY_RESULT(state, payload) {
        state.affordabilityResult = payload
    },
    ALL_PROPERTIES(state, payload) {
        state.properties = payload
    },
    SEARCH_PROPERTIES(state, payload) {
        state.properties = payload
    },
    FEATURED_PROPERTIES(state, payload) {
        state.featured_properties = payload
    },
    RECENT_PROPERTIES(state, payload) {
        state.recent_properties = payload
    },
    BELOW_PRICE_PROPERTIES(state, payload) {
        state.below_price_properties = payload
    },
    PROPERTY_DETAIL(state, payload) {
        state.property = payload;
    },
    SELECTED_PROPERTY(state, payload) {
        state.selectedProperty = payload

    },
    CALCULATION_REQUEST(state) {
        state.calculating = true;
    },
    AFFORDABLE_PROPERTIES(state, payload) {
        state.affordable_properties = payload;
    },

    AFFORDABILITY_REQUEST_SUCCESS(state, data) {
        state.calculating = false;
        hasAffordability = true;
    },
    ELIGIBILITY_REQUEST_SUCCESS(state, data) {
        state.calculating = false;
        hasEligibility = true;
    },


}
const actions = {
    async calculateAffordabilityAction({
        commit
    }, form) {

        commit("AFFORDABILITY_RESULT", form);
    },
    selectPropertyAction({
        commit
    }, pro) {
        commit("SELECTED_PROPERTY", pro);
        router.push({
            name: "Affordability"
        });
    },
    async affordablePropertiesAction({
        commit
    }, data) {
        console.log(data);
        await api.fetchAffordableProperties(data).then((res) => {
            let r = res.data.data;
            console.log("propertyhun",r)
            
            commit("AFFORDABLE_PROPERTIES", r);
        })
    },
    async allPropertiesAction({
        commit
    }) {
        await api.fetchAllProperties().then((res) => {
            let r = res.data.data;
                 console.log(r)
            // commit("LIST_STYLE", 'all');
            commit("ALL_PROPERTIES", r);
        })
    },
    async paginationAction({commit},data){
        let url = data.url;
        let mutator = data.mutator;
        let method = data.method
        let form = data.form;
        await api.paginationProperties(url,method,form).then((res)=>{
             let r = res.data.data;
             console.log(r)
             // commit("LIST_STYLE", 'all');
             commit(mutator, r);
        })
    },
    searchPropertiesAction({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            return api.searchProperties(data).then((res) => {
                let r = res.data.data;
                //  commit("LIST_STYLE", 'search');
                commit("SEARCH_PROPERTIES", r);
                resolve(r);
            })
        })

    },
    async featuredPropertiesAction({
        commit
    }) {
        await api.featuredProperties().then((res) => {
            let r = res.data.data;
            commit("FEATURED_PROPERTIES", r);
        })
    },
    async belowPricePropertiesAction({
        commit
    }, price) {
        console.log(price);
        await api.belowPriceProperties(price).then((res) => {
            let r = res.data.data;
            commit("BELOW_PRICE_PROPERTIES", r);
        })
    },
    async recentPropertiesAction({
        commit
    }) {
        await api.recentProperties().then((res) => {
            let r = res.data.data;
            commit("RECENT_PROPERTIES", r);
        })
    },
    async propertyDetailAction({commit}, slug) {
        await api.propertyDetail(slug).then((res) => {
            let r = res.data.data;
            commit("PROPERTY_DETAIL", r);
        })
    },
    async saveTourScheduleAction({commit},data){
        return new Promise((resolve,reject)=>{
            api.saveBookTour(data).then((res)=>{
                resolve(res);
            })
        })
    },
    async savePropertyEnquiryAction({commit},data){
        return new Promise((resolve,reject)=>{
            api.propertyEnquiry(data).then((res) => {
                resolve(res);
            })
        })
    }
}
const getters = {
    // getField,
}
const properties_module = {
    state,
    mutations,
    actions,
    getters
};

export default properties_module;
