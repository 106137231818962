import Vue from 'vue';

import Vuelidate from 'vuelidate'
// import VueSlideBar from 'vue-slide-bar'
// import carousel from "vue-owl-carousel";
// import RangeSlider from "vue-range-slider";
// import "vue-range-slider/dist/vue-range-slider.css";


Vue.use(Vuelidate);
// Vue.component('range-slider ', RangeSlider)
// Vue.component('carousel', carousel)
//layout componenent
import Frontend from '@/views/layouts/Frontend';
import Backend from '@/views/layouts/Backend';
import Offline from '@/views/layouts/Offline';
import AppHeader from './general/AppHeader';
import AppFooter from './general/AppFooter.vue';


// const MySkeleton = () => import(/*webpackChunkName: "general"*/'./general/MySkeleton')
// import MySkeleton from "./general/MySkeleton";
const AppLogo = () => import( /*webpackChuckName:"general" */ './general/AppLogo.vue');
const AppLoader = () => import( /*webpackChuckName:"general" */ './general/Loader.vue');

const Modal = () => import( /*webpackChunkName:"general" */ './general/Modal.vue');
const Pagination = () => import( /*webpackChunkName:"general" */ './general/Pagination.vue');

const AffordabilityResult = () => import( /*webpackChunkName:"calculator" */ '../modules/calculator/components/Result.vue');
const PropertyCard = () => import( /*webpackChunkName:"property" */ '../modules/properties/components/PropertyCard.vue');
const NewPropertyCard = () => import( /*webpackChunkName:"property" */ '../modules/properties/components/NewPropertyCard.vue');
const PropertyCardLoader = () => import( /*webpackChunkName:"property" */ '../modules/properties/components/PropertyCardLoader.vue');
const SelectedProperty = () => import( /*webpackChunkName:"property" */ '../modules/properties/components/SelectedProperty.vue');
// const Success = () => import(/*webpackChunkName:"general" */'./general/Success');






Vue.component('frontend-layout', Frontend);
Vue.component('backend-layout', Backend);
Vue.component('offline-layout', Offline);
Vue.component('app-logo', AppLogo);
Vue.component('app-header', AppHeader);
Vue.component('app-footer', AppFooter);
Vue.component('app-loader', AppLoader);

Vue.component('modal', Modal);
Vue.component('pagination', Pagination);

//affordability result
Vue.component('affordability-result', AffordabilityResult);
Vue.component('property-card', PropertyCard);
Vue.component('new-property-card', NewPropertyCard);
Vue.component('property-card-loader', PropertyCardLoader);
Vue.component('selected-property', SelectedProperty);
//form
// Vue.component('base-input',BaseInput);
// Vue.component('search-input',SearchInput);