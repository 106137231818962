import Vue from 'vue';
// import moment from 'moment';

Vue.filter('currency', function (value) {
    if(!isNaN(value)){
          let formatter = Intl.NumberFormat("NGN", {
              // style: "currency",
              minimumFractionDigits: 0
          }); //'₦ '+
          return '₦ ' + formatter.format(value);
    }
    return '₦ 00';
  
})
Vue.filter('price', function (value) {
    if(!isNaN(value)){
          let formatter = Intl.NumberFormat("NGN", {
              // style: "currency",
              minimumFractionDigits: 0
          }); //'₦ '+
          return '₦ ' + formatter.format(value);
    }
    return '₦ 00' ;
  
})
Vue.filter('truncate',function(text,length){
      if (text.length > length) {
          return text.substring(0, length) + '...';
      } else {
          return text;
      }
})
Vue.filter('capitalize', function (value) {
            if (!value) return ''
            value = value.toString()
})
Vue.filter('upper', function (value) {
    return value.toUpperCase();
})

Vue.filter('formatDate',function(value){

    // return moment(String(value)).format('DD/MM/YYYY hh:mm')
})
Vue.filter('calculateAge',function(v){
    // return moment().diff(moment(v, 'DD/MM/YYYY'), 'years')
})