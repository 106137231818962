<template>
  <div >
      <app-header/>
      
      <router-view></router-view>
      <app-footer/>
  </div>
</template>
<script>
import AppFooter from '../../components/general/AppFooter.vue'
import AppHeader from '../../components/general/AppHeader.vue'
export default {
  components: { AppHeader, AppFooter},
    
}
</script>