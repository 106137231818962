import axios from 'axios'
import NProgress from "nprogress";
import {
	TokenService
} from './TokenService'
import toast from './ToastService'
import Vue from 'vue'
import EventBus from './event'

axios.interceptors.request.use(config => {
	NProgress.start();
	EventBus.$emit("loading", true)
	return config;
});

// before a response is returned stop nprogress
axios.interceptors.response.use(
	function (response) {
		NProgress.done();
		EventBus.$emit("loading", false)
		return response;
	},
	function (error) {
		NProgress.done();
		EventBus.$emit("loading", false)
		// check for errorHandle config
		if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
			return Promise.reject(error);
		}

		if (error.response) {
			// console.log(error.response.data);
			try {
				toast.error(error.response.data.data);
			} catch (error) {
				toast.error('An error occured');
			}
		}
	}
)
const ApiService = {
	init(baseURL) {
		axios.defaults.baseURL = baseURL;
	},

	setHeader() {
		axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`
	},

	removeHeader() {
		axios.defaults.headers.common = {}
	},
	get(resource) {
		return axios.get(resource)
	},

	post(resource, data) {
		return axios.post(resource, data)
	},

	put(resource, data) {
		return axios.put(resource, data)
	},

	delete(resource) {
		return axios.delete(resource)
	},

	/**
	 * Perform a custom Axios request.
	 *
	 * data is an object containing the following properties:
	 *  - method
	 *  - url
	 *  - data ... request payload
	 *  - auth (optional)
	 *    - username
	 *    - password
	 **/
	customRequest(data) {
		return axios(data)
	}
}
export class ApiSource {
	constructor(baseUrl) {
		if (TokenService.getToken()) {
			// ApiSetUp.setHeader();
		}
		this.baseUrl = baseUrl
	}
	async sendrequest(http_method, url, data, ) {
		return await axios.request({
			method: http_method,
			url: this.baseUrl + url,
			data: data,
		});
	}
	//  authentication apis
	async login(authData) {
		return await this.sendrequest("post", "login", authData);
	}
	async register(authData) {
		return await this.sendrequest("post", "agent/register", authData);
	}
	async changepassword(authData) {
		return await this.sendrequest("post", "lender/change-password", authData);
	}
	async updateprofile(authData) {
		return await this.sendrequest("post", "lender/update-profile", authData);
	}
	 async allStates() {
	 	return this.sendrequest("get", "general/all-states");
	 }
	 async allCitiesInState(state_id) {
	 	return this.sendrequest("get", "general/all-cities/" + state_id);
	 }
	 async propertyTypeList() {
	 	return await this.sendrequest("get", "general/all-special-types");
	 }
	 async propertyStatusList() {
	 	return await this.sendrequest("get", "general/property-status");
	 }
	async calculateAffordability(data) {
		return await this.sendrequest("post", "general/affordability-test", data);
	}
	async fetchAffordableProperties(data) {
		// let r = data.amount + '/' + data.location;
		return await this.sendrequest("post", "general/property-affordability" ,data);
	}
	async fetchLenderRate() {
		// return await this.sendrequest("get", "general/lender-rates");
	}
	async fetchAllProperties() {
		return await this.sendrequest("get", "general/all-properties");
	}
	async paginationProperties(url,method,data=null) {
		let form = method=="post"?url+','+data: url;
		return await this.sendrequest(method, form);
	}
	async featuredProperties() {
		return await this.sendrequest("get", "general/featured-properties");
	}
	async recentProperties() {
		return await this.sendrequest("get", "general/recent-properties");
	}
	async belowPriceProperties(price) {
		return await this.sendrequest("get", "general/property-below-price/" + price);
	}
	async searchProperties(data) {
		return await this.sendrequest("post", "general/search-properties", data);
	}
	async propertyDetail(slug) {
		return await this.sendrequest("get", "general/property-detail/" + slug);
	}
	async savePrequalified(data) {
		return await this.sendrequest("post", "general/profile-request", data);
	}
	async saveBookTour(data) {
		return await this.sendrequest("post", "general/book-tour", data);
	}
	async propertyEnquiry(data) {
		return await this.sendrequest("post", "general/property-enquiry'", data);
	}

}
export default ApiService