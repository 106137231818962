<template>
    <div>
         

            <div class="modal fade" id="confirmationModal" tabindex="-1" role="dialog"
                aria-labelledby="confirmationModalTitle" style="display: none;" aria-modal="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header"><button type="button" class="close" data-dismiss="modal"
                                aria-label="Close"><i class="fa fa-times" aria-hidden="true"></i></button></div>
                        <div class="modal-body">
                            <div class="confirmation-wrapper">
                                <figure><img src="@/assets/img/questionImg.png" alt="Question Icon"></figure>
                                <p>Are you sure you want to continue?</p>
                                <div class="equity-content-wrapper">
                                    <h3>Your Equity Contribution is <strong>{{percentage}}%</strong></h3>
                                    <h2>{{down_payment|price}}. <small>00</small></h2>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="other-action-cta-btn pointer" @click.prevent="goToProperties">Check other
                                properties</button>
                            <button type="button" class="proceed-cta-btn pointer" @click="proceedToNextStep">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
import EventBus from '@/services/event'
import AffordabilityForm from './AffordabilityForm.vue'
import {FormMixin} from '@/mixins/form'/*  */
import {CalculatorMixin} from '@/mixins/CalculatorMixin'/*  */
import {AppMixin} from '@/mixins/AppMixin'
export default {
  components: {  AffordabilityForm },
    name:"DownPaymentModal",
    mixins:[FormMixin,CalculatorMixin, AppMixin],
    props:{
        
    },
    data(){
        return{
            modal:null,

          
        }
    },
    computed:{
        percentage(){
            let p = this.$store.state.calculator_module.form.down_rate;
            return p;
        },
        down_payment(){
             let p = this.$store.state.calculator_module.form.down_payment;
            return p;
        }
    },
    methods:{
      test(){},
      showModal(){
        $("#confirmationModal").modal('show');
      },
      hideModal(){
        $("#confirmationModal").modal('hide')
      },
      proceedToNextStep(){
           this.hideModal();
           this.processStepFunction(true,true);
        this.$store.dispatch("formStepAction",3);
      },
      goToProperties(){
          this.hideModal();
          this.processStepFunction(false,false);
          this.$store.dispatch("formStepAction",1);
          this.$router.push({name:'Properties'});
      }
    },
    created(){
        EventBus.$on('open-down-payment-modal',(t)=>{
            if(t){
                  this.showModal()
            }else{
                 this.hideModal();
            }
          
            console.log('show from afford')
        });
    },
    
}
</script>
<style  scoped>

</style>