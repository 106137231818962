<template>
  <countTo :startVal='start' :endVal='end' :duration='3000'></countTo>
</template>

<script>
  import countTo from 'vue-count-to';
  export default {
    components: { countTo },
    props:{
        start:{type:Number,required:true},
        end:{type:Number,required:true}
    },
    data () {
      return {
        // startVal: 0,
        // endVal: 2017
      }
    }
  }
</script>