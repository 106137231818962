import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from "vuex-persist";
import * as Cookies from "js-cookie";
import calculator_module from '@/modules/calculator/store/calculator_module'
import properties_module from '@/modules/properties/store/properties_module'
import general_module from '@/modules/general/store/general_module'
Vue.use(Vuex)
const vuexCookie = new VuexPersistence({
  restoreState: (key, storage) => Cookies.getJSON(key),
  saveState: (key, state, storage) =>
    Cookies.set(key, state, {
      expires: 3
    }),
  modules: ['auth_module', 'calculator_module'], //only save user module
  filter: (mutation) => mutation.type == 'logIn' || mutation.type == 'logOut'
})
const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  // reducer: (state) => ({
  //    calculator_module: state.calculator_module
  // }), //only save navigation module
  // filter: (mutation) => mutation.type == 'addNavItem'
})

export default new Vuex.Store({
  plugins: [
    vuexLocal.plugin
  ],
  modules: {
    general_module,
    calculator_module,
    properties_module
  }
})
