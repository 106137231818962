<template>
    <div>
         <!-- <div class="modal fade" id="AffordabilityTestModal" tabindex="-1" role="dialog"
                aria-labelledby="AffordabilityTestModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-affordability-test modal-dialog-centered" role="document">
                    <div class="modal-content modal-affordability-test">
                        <div class="modal-header"><button type="button" class="close" data-dismiss="modal"
                                aria-label="Close"><span aria-hidden="true">X</span></button></div>
                        <div class="modal-body">
                            <div class="affordability-test-wrapper">
                                <div class="affordability-form-top-content">
                                    <h2>How much can i Afford</h2>
                                    <p>Calculate the home loan you qualify for, and how much you can expect to pay
                                        monthly on your home loan repayments.</p>
                                </div>
                                <div class="form-progress-wrapper">
                                    <div class="progress-bar">
                                        <div class="step">
                                            <div class="check fas fa-chevron-down active"></div>
                                            <div class="bullet active"><span>1</span></div>
                                            <p class="active">Affordability Test</p>
                                        </div>
                                        <div class="step">
                                            <div class="check fas fa-chevron-down pending"></div>
                                            <div class="bullet pending"><span>2</span></div>
                                            <p class="pending">Down Payment</p>
                                        </div>
                                        <div class="step">
                                            <div class="check fas fa-check pending"></div>
                                            <div class="bullet pending"><span>3</span></div>
                                            <p class="pending">Complete Profile</p>
                                        </div>
                                    </div>
                                </div>
                                <affordability-form :formType="true">
                                  <template v-slot:button>                       <button type="button" @click.prevent="submitModalAffordability"
                              class="affordability-form-btn">Continue</button>
                                    </template>
                                </affordability-form>
                                
                            </div>
                        </div>
                    </div>
                </div>
        </div> -->
        <div class="modal fade" id="AffordabilityTestModal" tabindex="-1" role="dialog"
                aria-labelledby="AffordabilityTestModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-affordability-test modal-dialog-centered" role="document">
                    <div class="modal-content modal-affordability-test">
                        <div class="modal-header"><button type="button" class="close" data-dismiss="modal"
                                aria-label="Close"><span aria-hidden="true">X</span></button></div>
                        <div class="modal-body">
                            <div class="affordability-test-wrapper">
                                <div class="affordability-form-top-content">
                                    <h2>How much can I afford ?</h2>
                                    <p>Calculate the home loan you qualify for, and how much you can expect to pay
                                        monthly on your home loan repayments.</p>
                                </div>
                                <div class="form-progress-wrapper">
                                    <div class="progress-bar">
                                        <div class="step">
                                            <div class="check fas fa-chevron-down active"></div>
                                            <div class="bullet active"><span>1</span></div>
                                            <p class="active">Affordability Test</p>
                                        </div>
                                        <div class="step">
                                            <div class="check fas fa-chevron-down pending"></div>
                                            <div class="bullet pending"><span>2</span></div>
                                            <p class="pending">Down Payment</p>
                                        </div>
                                        <div class="step">
                                            <div class="check fas fa-check pending"></div>
                                            <div class="bullet pending"><span>3</span></div>
                                            <p class="pending">Complete Profile</p>
                                        </div>
                                    </div>
                                </div>
                               
                                <affordability-form :formType="true">
                                    <template v-slot:button>
                                        <button type="button" @click.prevent="submitModalAffordability"
                                                class="affordability-form-btn">
                                                    <span v-if="!apiloading">Continue</span>
                                                    <span v-if="apiloading">loading...</span>
                                                </button>
                                       
                                        </template>
                                    </affordability-form>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
import EventBus from '@/services/event'
import AffordabilityForm from './AffordabilityForm.vue'
import {FormMixin} from '@/mixins/form'/*  */
import {CalculatorMixin} from '@/mixins/CalculatorMixin'/*  */
import {AppMixin} from '@/mixins/AppMixin'
export default {
  components: {  AffordabilityForm },
    name:"AffordabilityModal",
    mixins:[FormMixin,CalculatorMixin, AppMixin],
    data(){
        return{
            modal:null,
          
        }
    },
    methods:{
      test(){},
      showModal(){
        $("#AffordabilityTestModal").modal('show');
      },
      hideModal(){
        $("#AffordabilityTestModal").modal('hide')
      }
    },
    created(){
        EventBus.$on('open-affordability-modal',(t)=>{
            if(t){
                  this.showModal()
            }else{
                 this.hideModal();
            }
          
            console.log('show from afford')
        });
        // EventBus.$on('hide-affordability-modal',(t)=>{
        //     this.hideModal();
        // });
    },
    mounted(){
          // this.modal = new Modal(this.$refs.test)
    }
}
</script>
<style  scoped>
  @media (max-width:1000px){
      .modal-dialog.modal-affordability-test {
        max-width:100% !important;
    }
    .modal-content.modal-affordability-test {
        padding: 0.8rem;
    }
      .progress-bar .step p.pending{
        font-size:0.7rem !important;
    }
    .step p.active {
         font-size:0.7rem !important;
    }
  }
</style>