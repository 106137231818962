<template>
    <div >
        <div class="toggle-align-right pointer">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4 4.25C3.58579 4.25 3.25 4.58579 3.25 5C3.25 5.41422 3.58579 5.75 4 5.75L20 5.75C20.4142 5.75 20.75 5.41422 20.75 5C20.75 4.58579 20.4142 4.25 20 4.25L4 4.25ZM9.25 12C9.25 11.5858 9.58579 11.25 10 11.25L20 11.25C20.4142 11.25 20.75 11.5858 20.75 12C20.75 12.4142 20.4142 12.75 20 12.75L10 12.75C9.58579 12.75 9.25 12.4142 9.25 12ZM3.25 19C3.25 18.5858 3.58579 18.25 4 18.25L20 18.25C20.4142 18.25 20.75 18.5858 20.75 19C20.75 19.4142 20.4142 19.75 20 19.75L4 19.75C3.58579 19.75 3.25 19.4142 3.25 19Z"
                    fill="#16465C" />
            </svg>
        </div>
    </div>
</template>
<script>
import EventBus from '@/services/event'
export default {
    name:"MobileMenuIcon",
    data(){
        return{
            show_menu:false
        }
    },
    methods:{
        toggleMenu(){
            // this.show_menu = !this.show_menu;
            EventBus.$emit('show-side-menu',true)
      
        }
    }
}
</script>
<style scoped>
    @media (max-width:1000px){
        /* .mobileham {
            display: -webkit-flex;
            display: flex;
            position: relative;
            right: 1rem;
            flex-direction: column;
        }
        .animated {
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
        }
        .slideInLeft {
            -webkit-animation-name: slideInLeft;
            animation-name: slideInLeft;
        }
        .ham1, .ham2, .ham3 {
            display: inline;
            height: 2px;
            background: #333;
            margin-top: .4rem;
        }
        .ham1, .ham2 {
            width: 29px;
        }
        .ham3{
            width:15px;
            margin-left: 14px;
        }
        */
    }
  
</style>