<template>
    <div>
         <section class="real-estate-dev">
                <div class="container-fluid">
                    <div class="real-estate-dev-wrapper">
                        <div class="real-estate-dev-top-content">
                            <h2>Real Estate Developers</h2>
                        </div>
                        <div class="real-estate-dev-list">
                            <div class="row">


                                <div class="col-lg-3">
                                    <div class="real-estate-dev-child">
                                        <div class="real-estate-dev-img"
                                             :style="{ backgroundImage: 'url('+require('@/assets/img/dev-bg-img.png')+ ')' }"
                                            >
                                            <div class="real-estate-dev-logo">
                                                <img src="@/assets/img/icons/brains-and-hammers.png"
                                                    alt="Brains & Hammers">
                                            </div>
                                        </div>
                                        <div class="real-estate-dev-info">
                                            <p>Brains & Hammers</p>
                                            <a href="" class="">View Properties >></a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-3">
                                    <div class="real-estate-dev-child">
                                        <div class="real-estate-dev-img"
                                         :style="{ backgroundImage: 'url('+require('@/assets/img/dev-bg-img.png')+ ')' }"
                                           >
                                            <div class="real-estate-dev-logo mixa-africa">
                                                <img src="@/assets/img/icons/mixa-africa.png" alt="Mixta Africa">
                                            </div>
                                        </div>
                                        <div class="real-estate-dev-info">
                                            <p>Mixta Africa</p>
                                            <a href="" class="">View Properties >></a>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-lg-3">
                                    <div class="real-estate-dev-child">
                                        <div class="real-estate-dev-img"
                                             :style="{ backgroundImage: 'url('+require('@/assets/img/dev-bg-img.png')+ ')' }">
                                            <div class="real-estate-dev-logo urban-shelter">
                                                <img src="@/assets/img/icons/urban-shelter.png" alt="Urban Shelta">
                                            </div>
                                        </div>
                                        <div class="real-estate-dev-info">
                                            <p>Urban Shelta</p>
                                            <a href="" class="">View Properties <i class="fa fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-3">
                                    <div class="real-estate-dev-child">
                                        <div class="real-estate-dev-img"
                                             :style="{ backgroundImage: 'url('+require('@/assets/img/dev-bg-img.png')+ ')' }">
                                            <div class="real-estate-dev-logo alpha-mead">
                                                <img src="@/assets/img/icons/alpha-mead.png" alt="Alpha Mead Group">
                                            </div>
                                        </div>
                                        <div class="real-estate-dev-info">
                                            <p>Alpha Mead Group</p>
                                            <a href="" class="">View Properties >></a>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
</template>
<script>
export default {
    name:"PartnerCard"
}
</script>
<style  scoped>
  
   @media (max-width:1000px){
    .real-estate-dev-child {
        margin-top:1rem;
    }
    .row-count{
        text-align: center;
    }
   }
</style>