<template>
    <div>
     
            


                <nav id="nav-scroll" class="navbar navbar-expand-md navbar-dark navbar-light custom-navbar sticky-top">
                <div class="container-fluid">
                    <a class="d-xl-block" href="/">
                        <img class="navbar-brand" src="@/assets/img/hsf-theme-logo.png" alt="Housing Solution Fund">
                    </a>

                    <div class="nav-link screen414 d-flex">
                        <div class="d-flex justify-content-start">
                            <a class="nav-link auth-btn" href="#">Login</a>
                            <div class="toggle-align-right pointer" @click="openMenu">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M4 4.25C3.58579 4.25 3.25 4.58579 3.25 5C3.25 5.41422 3.58579 5.75 4 5.75L20 5.75C20.4142 5.75 20.75 5.41422 20.75 5C20.75 4.58579 20.4142 4.25 20 4.25L4 4.25ZM9.25 12C9.25 11.5858 9.58579 11.25 10 11.25L20 11.25C20.4142 11.25 20.75 11.5858 20.75 12C20.75 12.4142 20.4142 12.75 20 12.75L10 12.75C9.58579 12.75 9.25 12.4142 9.25 12ZM3.25 19C3.25 18.5858 3.58579 18.25 4 18.25L20 18.25C20.4142 18.25 20.75 18.5858 20.75 19C20.75 19.4142 20.4142 19.75 20 19.75L4 19.75C3.58579 19.75 3.25 19.4142 3.25 19Z"
                                        fill="#16465C" />
                                </svg>
                            </div>
                           
                        </div>

                        <div class="toggle-close" @click="closeMenu">
                            <i class="fa fa-times fa-2x"></i>
                        </div>
                    </div>



                    <div class="navbar-collapse" id="navbarText">
                        <ul class="navbar-nav ml-auto d-none d-xl-block navigation">
                               <li class="nav-item " :class="$route.name=='Home'?'active':'' ">
                                        <router-link :to="{name:'Home'}" class="nav-link" >Home</router-link>
                                </li>
                                <li class="nav-item" :class="$route.name=='Properties'?'active':'' ">
                                            <router-link :to="{name:'Properties',params:{type:'all'}}" class="nav-link" exact>Properties</router-link>
                                </li>
                                <li class="nav-item" :class="$route.name=='Mortgage'?'active':'' ">
                                        <router-link :to="{name:'Mortgage'}" class="nav-link" exact>Mortgage</router-link>
                                </li>

                                <li class="nav-item" :class="$route.name=='ContactUs'?'active':'' ">
                                    <router-link :to="{name:'ContactUs'}" class="nav-link" exact>Contact Us</router-link>
                                </li>
                                <li class="nav-item" :class="$route.name=='FAQ'?'active':'' ">
                                        <router-link :to="{name:'FAQ'}" class="nav-link" exact>FAQs</router-link>
                                </li>
                                <!-- <li class="nav-item" :class="$route.name=='Login'?'active':'' ">
                                    <router-link :to="{name:'Login'}" class="nav-link" exact>Login</router-link>
                                </li>
                                <li class="nav-item" >
                                    <router-link :to="{name:'Register'}" class="nav-link btnGetStarted" exact>Sign Up</router-link>
                                </li> -->
                        </ul>
                    </div>
                    <a class="nav-link screen800" href=""></a>
                </div>
            </nav>

            <div class="mobileMenuWrapper" >
                <div class="mobileMenuContent">
                    <div class="menuMobileHeaderWrapper">
                        <div class="menuMobileBottomWrapper">
                            <ul class="menuMobileBottomList">
                                <li class="nav-item " :class="$route.name=='Home'?'active':'' ">
                                        <router-link :to="{name:'Home'}" class="nav-link" >Home</router-link>
                                </li>
                                <li class="nav-item" :class="$route.name=='Properties'?'active':'' ">
                                            <router-link :to="{name:'Properties',params:{type:'all'}}" class="nav-link" exact>Properties</router-link>
                                </li>
                                <li class="nav-item" :class="$route.name=='Mortgage'?'active':'' ">
                                        <router-link :to="{name:'Mortgage'}" class="nav-link" exact>Mortgage</router-link>
                                </li>

                                <li class="nav-item" :class="$route.name=='ContactUs'?'active':'' ">
                                    <router-link :to="{name:'ContactUs'}" class="nav-link" exact>Contact Us</router-link>
                                </li>
                                <li class="nav-item" :class="$route.name=='FAQ'?'active':'' ">
                                        <router-link :to="{name:'FAQ'}" class="nav-link" exact>FAQs</router-link>
                                </li>
                              
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
import AppLogo from './AppLogo.vue'
import MobileMenuIcon from './MobileMenuIcon.vue'
import {AppMixin} from '@/mixins/AppMixin'
import EventBus from '@/services/event'
export default {
  components: { AppLogo,MobileMenuIcon },
    name:"AppHeader",
    props:{
        showShadow:{type:Boolean,default:true}
    },
    mixins:[AppMixin],
    data(){
        return {
            show_menu:false
        }
    },
    watch: {
    '$route' (to, from) {
          this.closeMenu()
    }
    }, 
    methods: {
           closeMenu(){
              $(".mobileMenuWrapper, .toggle-close").hide();
                $(".toggle-align-right").show();
      
        },
        openMenu(){
              $(".mobileMenuWrapper, .toggle-close").show();
            $(".toggle-align-right").hide();
        }
    },
    created(){
    //       EventBus.$on("show-side-menu",(t)=>{
    //     this.show_menu=t;
    //     console.log(this.show_menu)
    //   })
    }
}
</script>
<style  scoped>
    .sticky-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    margin-bottom:3rem;
}

     .show-side-menu{
        display: block;
    }
    .hide-side-menu{
        display: none;
    }
</style>