import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from "nprogress";
import Home from '../views/Home.vue'
import calculator_routes from '@/modules/calculator/calculator_routes'
import general_routes from '@/modules/general/general_routes'
import properties_routes from '@/modules/properties/properties_routes'

Vue.use(VueRouter)

const routes = general_routes.concat(
  properties_routes,
  calculator_routes
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // window.scrollTo(0, 0);
    return {
      x: 0,
      y: 0
    }
  }
})
router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
    // EventBus.$emit("loading", true)
  }
  next();
});

const DEFAULT_TITLE = 'Housing Fund';
router.afterEach((to, from) => {
   NProgress.done();
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});
export default router