import EventBus from '../services/event'
export const AppMixin ={
    data(){
        return{
            apiloading:false
        }
        
    },
    computed:{
        property_type_list(){
            let t = this.$store.state.general_module.property_types;
            // console.log('type:'+t)
            return t;
        },
        property_status_list(){
            let t = this.$store.state.general_module.property_status_list;
            // console.log('type:'+t)
            return t;
        },
         states() {
             let s = this.$store.state.general_module.states;
             return s;
         },
    },
    methods: {
          removeCommaFromNumber(v) {
              return parseInt(v.replace(/,/g, ""));
          },
        showModal(){
            console.log('show modal')
            EventBus.$emit('show-modal',true);
        },
        hideModal(){
              console.log('hide modal')
            EventBus.$emit('hide-modal',false);
        },
        showAffordabilityModal(){
            EventBus.$emit('open-affordability-modal', true);
        },
        showRequestModal(){
             console.log('request open')
             EventBus.$emit('open-request-modal', true);
        }
    
     
    },
    created() {
        EventBus.$on("loading",(t)=>{
            this.apiloading = t;
        })
    },
 }