import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import JQuery from 'jquery'
window.$ = window.JQuery = JQuery;
import filter from './plugins/filter'
import 'bootstrap'
import component from './components/component'
// require('./assets/js/main.js')
// import VueGlide from 'vue-glide-js'
// import 'vue-glide-js/dist/vue-glide.css'

// Vue.use(VueGlide)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
