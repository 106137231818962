<template>
  <div>
    <!-- <div class="property-card" @click.prevent="chooseProperty">
      <div
        class="property-image-holder" :style="{ backgroundImage: 'url('+property.property_cover_image + ')' }"
      ></div>
      <div class="property-deets">
        <div class="card-price-holder">
          <div class="card-property-status text-uppercase">{{property.property_status.name}}</div>
          <h4 class="card-price">{{property.property_price | price}}</h4>
        </div>
        <div class="card-name-holder">
          <h3 class="card-title">{{property.property_name  | truncate(50)}} </h3>
          <div class="property-card-extras">
            <div class="card-location">{{property.city.name}}, {{property.state.name}}</div>
          </div>
        </div>
        <div class="project-logo">
        </div>
        <div class="card-bed-bath-holder">
          <div class="card-inner-side">
            <div class="card-icon" :style="{ backgroundImage: 'url('+require('@/assets/img/icons/bed.png' )+ ')' }">
            
            </div>
            <div class="card-bed-bath">{{property.property_bedrooms}} Beds</div>
          </div>
          <div class="card-inner-divider"></div>
          <div class="card-inner-side">
            <div class="card-icon" :style="{ backgroundImage: 'url('+require('@/assets/img/icons/bath.png' )+ ')' }"></div>
            <div class="card-bed-bath">{{property.property_bathrooms}} Baths</div>
          </div>
          <div class="card-inner-divider"></div>
          <div class="card-inner-side">
            <div class="card-icon" :style="{ backgroundImage: 'url('+require('@/assets/img/icons/size.png' )+ ')' }"></div>
            <div class="card-bed-bath">{{property.property_size}} Sqm</div>
          </div>
        </div>
        <div class="mortgage-deets-holder">
          <div class="card-inner-block">
            <p class="mortgage-heading">Buy on a mortgage</p>
            <div class="mortgage-wrapper">
              <p class="mortage-value">{{property.estimated_repayment.monthly_payment|price}}</p>
              <div class="mortgage-freq">/month</div>
            </div>
          </div>
          <div class="card-inner-divider long"></div>
          <div class="card-inner-block">
            <p class="repayment-heading">Payback over</p>
            <div class="repayment-wrapper">
              <p class="repayment-period">{{property.estimated_repayment.tenure}}</p>
              <div class="repayment-freq">/Years</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="property-list v2"> -->
      <div class="property-cards pointer" @click.prevent="chooseProperty">
              <div class="property-img"
                  :style="{ backgroundImage: 'url('+property.property_cover_image + ')' }">
              </div>

              <div class="property-cards-info">
                  <div class="property-status-price">
                      <span class="badge">{{property.property_status.name}}</span>
                      <h5>{{property.property_price | price}}.<small>00</small></h5>
                  </div>

                  <div class="property-location">
                      <h5>{{property.property_name  | truncate(50)}}</h5>
                      <p>{{property.city.name}}, {{property.state.name}}, Nigeria </p>
                  </div>

                  <div class="property-bed-bath-size-info">
                      <div class="bed-info">
                          <img src="@/assets/img/icons/bed.png" alt="Beds">
                          <span>{{property.property_bedrooms}} beds</span>
                      </div>

                      <div class="bath-info">
                          <img src="@/assets/img/icons/bath.png" alt="Baths">
                          <span>>{{property.property_bathrooms}} Baths</span>
                      </div>

                      <div class="size-info">
                          <img src="@/assets/img/icons/size.png" alt="Size">
                          <span>{{property.property_size}} Sqm</span>
                      </div>
                  </div>

                  <div class="property-mortgage-deals">
                      <div class="property-mortgage-amount">
                          <p>Buy on a mortgage</p>
                          <h5 v-if="property.estimated_repayment">{{property.estimated_repayment.monthly_payment|price}} <small>
                                  /month
                              </small></h5>
                      </div>
                      <div class="property-mortgage-payback">
                          <p>Payback over</p>
                          <h5  v-if="property.estimated_repayment">{{property.estimated_repayment.tenure}} <small>
                                  / Years
                              </small></h5>
                      </div>
                  </div>
              </div>
        </div>
    <!-- </div> -->
  </div>
</template>
<script>
import {CalculatorMixin} from '@/mixins/CalculatorMixin'
export default {
  name: "NewPropertyCard",
  mixins:[CalculatorMixin],

    props:{
        // btnText:{type:String,default:'See details'},
        property:{type:Object},
        isChoose:{type:Boolean,default:false}
    },
      data(){
        return{
          tenure:20,
          rate:9
        }
      },
      computed:{
        monthly(){
          let r = this.calculateMonthlyPayment(this.property.property_price,this.rate,this.tenure)
          return r;
        }
      },
    methods:{
        chooseProperty(){
            console.log("choose property")
            if(this.isChoose){
                this.$store.dispatch("selectPropertyAction",this.property);
                 this.$store.commit("SAVE_SELECTED_PROPERTY",this.property)
                 this.$store.dispatch("formStepAction",2);

            }else{
                // go detail page
                this.$router.push({name:"PropertyDetail",params:{slug:this.property.slug}})
            }
        }
    }
};
</script>
<style scoped>

.property-img {
    height: 186px;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-position: 50%;
    background-size: cover;
} 

</style>
