<template>
<div class="">
   
     <section class="hero-banner" :style="{backgroundImage:'url('+require('@/assets/img/hero-banner-sample-img.jpg')+')'}">
                <div class="container-fluid">
                    <div class="row">
                        <div class="offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div class="hero-banner-ltr text-center">
                                <h2>
                                    Find your dream <strong class="theme-colsor">Home and Mortgage</strong>
                                </h2>
                                <p>
                                    Housing Solution Fund provides Valuation, Sell, Buy, Mortgage, Invest property and
                                    other Real Estate services worldwide
                                </p>
                                <a class="default-cta-btn pointer" @click.prevent="showAffordabilityModal" >Apply for a Home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
</div>
</template>
<script>
import {AppMixin} from '@/mixins/AppMixin';
import Marquee from './Marquee.vue';
// import PartnerCard from './PartnerCard.vue'
export default {
  components: { Marquee },
    name:"Hero",
    mixins:[AppMixin]
//   components: { PartnerCard },
    
}
</script>
<style scoped >
/* Hero banner before is remove from app.css */
  .hero-banner{
     background-blend-mode: darken;
    background-color: rgba(7, 28, 31, 0.6);;
  }
</style>