<template>
    <div >
          <div class="side-menu" :class="[show_menu?'show-side-menu':'hide-side-menu']">
              <div class="top">
                  <div class="logo mt-3">
                       <app-logo :useImg="false" :isWhite="false" class="pl-3"/>
                  </div>
                 
                <button class="close  mt-3 mr-3" @click="closeMenu">X</button>
              </div>
              <div class="menu">
                   <ul class="navbar-nav ml-auto navigation">
                        <li class="nav-item " :class="$route.name=='Home'?'active':'' ">
                                <router-link :to="{name:'Home'}" class="nav-link" >Home</router-link>
                        </li>
                        <li class="nav-item" :class="$route.name=='Properties'?'active':'' ">
                                    <router-link :to="{name:'Properties',params:{type:'all'}}" class="nav-link" exact>Properties</router-link>
                        </li>
                        <li class="nav-item" :class="$route.name=='Mortgage'?'active':'' ">
                                <router-link :to="{name:'Mortgage'}" class="nav-link" exact>Mortgage</router-link>
                        </li>

                        <li class="nav-item" :class="$route.name=='ContactUs'?'active':'' ">
                            <router-link :to="{name:'ContactUs'}" class="nav-link" exact>Contact Us</router-link>
                        </li>
                        <li class="nav-item" :class="$route.name=='FAQ'?'active':'' ">
                                <router-link :to="{name:'FAQ'}" class="nav-link" exact>FAQs</router-link>
                        </li>
                        <!-- <li class="nav-item" :class="$route.name=='Login'?'active':'' ">
                             <router-link :to="{name:'Login'}" class="nav-link" exact>Login</router-link>
                        </li>
                        <li class="nav-item" >
                             <router-link :to="{name:'Register'}" class="nav-link btnGetStarted" exact>Sign Up</router-link>
                        </li> -->
                            <!-- <a class="nav-link btnGetStarted" href="register.html">Sign Up</a></li> -->
                    </ul>
              </div>
          </div>
        
    </div>
</template>
<script>
import EventBus from '@/services/event'
export default {
    name:"MobileMenuIcon",
     data(){
        return{
            show_menu:false
        }
    },
    watch: {
    '$route' (to, from) {
          this.closeMenu()
    },     
    },
    methods:{
          closeMenu(){
            // this.show_menu = !this.show_menu;
           this.show_menu =false;
      
        }
    },
    created(){
      EventBus.$on("show-side-menu",(t)=>{
        this.show_menu=t;
        console.log(this.show_menu)
      })
    }
}
</script>
<style scoped>
      .show-side-menu{
        display: block;
    }
    .hide-side-menu{
        display: none;
    }
    .side-menu{
       background-color: #fff;
        width:280px;
        height: 100%;
        position: fixed;
        z-index: 2000;
        left:0;
        top:0;
        box-shadow: 0px 4px 16px rgb(17 17 17 / 4%);
        transition: 0.5s;
    }
    .top{
        display: flex;
        justify-content: space-between;
    }
    .logo{
        height: 60px;
        width: 100px;
    }
    .menu{
        margin-top:1rem;
    }
    @media (max-width:1000px){
         .nav-link{
             color:#6e7191;
         }
        .navbar-nav .active >.nav-link {
            color: #16465C !important;
            border-bottom: none;
            line-height: 20px;
            text-align: left;
        }
    }
        
  
</style>