import {
    isLoggedIn
} from '@/router/middlewares/guards.js';
import multiguard from 'vue-router-multiguard';
const calculator_routes = [
        {
            path: '/affordability',
            name: 'Affordability',
            component: () => import( /* webpackChunkName: "pages" */ './views/Affordability.vue'),
            meta: {
                layout: 'frontend'
            }
        },


];
export default calculator_routes;