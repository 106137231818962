<template>
    <div>
       <div class="modal fade" id="congratulationModal" tabindex="-1" role="dialog"
                aria-labelledby="congratulationModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content congratulation">
                        <div class="modal-header"><button type="button" class="close" data-dismiss="modal"
                                aria-label="Close"><span aria-hidden="true"><i class="fa fa-times"
                                        aria-hidden="true"></i></span></button></div>
                        <div class="modal-body">
                            <div class="congratulation-wrapper">
                                <figure><img src="@/assets/img/congratsIcon.png" alt="Congratulations Icon">
                                </figure>
                                <h3>You have been Pre-Qualified to Apply for a Mortgage</h3>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="proceed-cta-btn congratulation-btn pointer"
                                @click="startApplication()">Close</button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
import EventBus from '@/services/event'
export default {
    name:"ContratModal",
    methods:{
        showModal(){
            $("#congratulationModal").modal('show')
        },
        hideModal(){
            $("#congratulationModal").modal('hide')
        },
        startApplication(){
            this.$store.commit("GO_TO_STEP",1);
            this.hideModal();
            window.location.href='/'
        }
    },
    created(){
        EventBus.$on('prequalified-modal',(t)=>{
            if(t){
                this.showModal();
            }else{
                this.hideModal();
            }
        })
    }
}
</script>
<style lang="css" scoped>
    .congratulation-btn{
        cursor: pointer;
    }
</style>