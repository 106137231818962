<template>
  <div >
        <hero/>
        <marquee/>
        <search-tab />
        <featured-properties/>
        <how-it-work/>
        <partner-card/>
        <fact-counter/>
            <!-- <review-section/> -->
             

            

           
  </div>
</template>

<script>
// @ is an alias to /src

import {AppMixin} from '@/mixins/AppMixin';
import SearchTab from '@/modules/properties/components/SearchTab.vue'
import DownPaymentModal from '../../calculator/components/DownPaymentModal.vue';
import EventBus from '@/services/event'
import Hero from '../components/Hero.vue';
import HowItWork from '../components/HowItWork.vue';
import PartnerCard from '../components/PartnerCard.vue';
import ReviewSection from '../components/ReviewSection.vue';
import Marquee from '../components/Marquee.vue';
import FeaturedProperties from '@/modules/properties/components/FeaturedProperties.vue';
import FactCounter from '../components/FactCounter.vue';
export default {
  name: 'Home',
  mixins:[AppMixin],
  components: {
   SearchTab,
    Hero,
    HowItWork,
    PartnerCard,
    ReviewSection,
    Marquee,
    FeaturedProperties,
    FactCounter
  },
 
    data(){
        return{
            responsive: { 0: { items: 1, nav: true }, 600: { items: 3, nav: true } },
            settings:{
                dots: false,
                arrows:true,
                infinite: false,
                speed: 500,
                slidesToShow: 3,
                swipe:true,
                slidesToScroll: 1,
                initialSlide: 0,
                centerMode:false,
                variableWidth:true,
                responsive: [
                    {
                        breakpoint: 1280,
                        settings:{
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings:{
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            initialSlide: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            }
        }
    },
    computed:{
        belowproperties(){
            let p = this.$store.state.properties_module.below_price_properties;
            return p;
        },
        featuredProperties(){
              let p = this.$store.state.properties_module.featured_properties;
            return p;
        },
        recentProperties(){
              let p = this.$store.state.properties_module.recent_properties;
            return p;
        }
    },
    methods:{
        fetchBelowpriceProperties(){
            this.$store.dispatch("belowPricePropertiesAction",15000000)
        },
        fetchFeaturedProperties(){
            this.$store.dispatch("featuredPropertiesAction")
            this.$store.dispatch("getLenderRateAction")
        },
        fetchRecentProperties(){
            this.$store.dispatch("recentPropertiesAction")
        },
        goToPropertyPage(){
          this.$router.push({name:'Properties'})
        },
        openDownPayment(){
            EventBus.$emit('open-down-payment-modal',true)
        }
    },
    created(){
        // this.fetchBelowpriceProperties();
        this.fetchFeaturedProperties();
        // this.fetchRecentProperties();
    }
}
</script>
<style  scoped>
   
    /* Recent Listing Line */
.property-listing-top-content h2::after {
    position: absolute;
    content: '';
    left: 30%;
    /* margin-left: 20px; */
    height: 1.5px;
    width: 63%;
    background: #A0A3BD;
    margin-top: 15px;
}
.owl-theme .owl-nav [class*='owl-'] {
    width:60px;
    height: 60px;
    border-radius: 50%;
}
.mynav{

}
.mynav .owl-prev,.owl-next{
     width:60px;
    height: 60px;
    border-radius: 50%;
}
@media (max-width:500px){
    .property-listing{
        margin-top:-4rem;
    }
      .property-listing-top-content h2::after {
       display: none;
    }

}
</style>
