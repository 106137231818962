<template>

 <footer class="genFooter">
                <div class="container-fluid">
                    <div class="genFooterTopWrapper">
                        <div class="row">
                            <div class="offset-lg-3 col-lg-6">
                                <div class="footer-subscriber-form text-center">
                                    <h2>Subscribe to our Newsletter</h2>
                                    <p>Never miss an opportunity, put your emails address and get started.</p>
                                    <form class="footer-subscriber-form-wrapper">
                                        <div class="footer-subscriber-form-content">
                                            <div><svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                    <path
                                                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                                    </path>
                                                    <polyline points="22,6 12,13 2,6"></polyline>
                                                </svg></div>
                                            <div><input type="email" class="form-control"
                                                    placeholder="Enter your Email Address"></div>
                                            <div><button class="subscriber-btn">Submit</button></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-12 col-sm-12">
                                <div class="genFooterBottomNav">
                                    <figure><img src="@/assets/img/hsf-default-white-logo.png"
                                            alt="Housing Solution Funds">
                                    </figure>
                                    <p><b>Housing Solution Fund</b> has gone above and beyond in helping is with our
                                        first home. From advice and working around our schedules to sending us
                                        assistance and minor renovations</p>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12 col-sm-12">
                                <div class="genFooterBottomNav">
                                    <h4>Support</h4>
                                    <ul>
                                        <li><a href="">Help Videos</a></li>
                                        <li><a href="">Accessories</a></li>
                                        <li><a href="">View Bookings</a></li>
                                        <li><a href="">Features</a></li>
                                        <li><a href="">CSR</a></li>
                                        <li><a href="">Terms and Policy</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12 col-sm-12">
                                <div class="genFooterBottomNav">
                                    <h4>About</h4>
                                    <ul>
                                        <li><a href="">Payment &amp; Tax</a></li>
                                        <li><a href="">Terms of Service</a></li>
                                        <li><a href="">Contact</a></li>
                                        <li><a href="">Announcement</a></li>
                                        <li><a href="">CSR</a></li>
                                        <li><a href="">News</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-12 col-sm-12">
                                <div class="genFooterBottomNav">
                                    <h4>Our Address</h4>
                                    <address>
                                        <p>Plot 15A Abayomi Durosinmi-Etti Street, Lekki Phase 1(Ocean Side), Lagos.</p>
                                    </address>
                                    <div class="genFooterSocials">
                                        <ul>
                                            <li><a class="socials" target="_blank" href="">
                                                    <img src="@/assets/img/icons/facebook.svg" alt="" />
                                                </a>
                                            </li>
                                            <li>
                                                <a class="socials" target="_blank" href="/">
                                                    <img src="@/assets/img/icons/instagram.svg" alt="" />
                                                </a>
                                            </li>
                                            <li><a class="socials" target="_blank" href="/">
                                                    <img src="@/assets/img/icons/twitter.svg" alt="" />
                                                </a></li>
                                            <li><a class="socials" target="_blank" href="/">
                                                    <img src="@/assets/img/icons/youtube.svg" alt="" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <h4>Our Partners</h4>
                                    <figure><img src="@/assets/img/shelterAfrice.png" alt="Shelter Afrique"></figure>
                                    <figure><img src="@/assets/img/infacredit.png" alt="Infra Credit"></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="genFooterBottomWrapper">
                        <div class="copyrights">
                            <p>Copyright &copy; 2021 Housing Solution Fund. Alright Reserved.</p>
                        </div>
                    </div>
                </div>
</footer>

   
</template>
<script>
import AppLogo from './AppLogo.vue'
export default {
    name:"AppFooter",
    components:{
        AppLogo,
    }
}
</script>
<style scoped >

/* @media (max-width:1000px){
    footer{
        padding:1rem;
    }
    
    .genFooterTopWrapper .footer-subscriber-form {
            padding: 0.5rem;
            margin-bottom: 2rem;
            
    }
    .footer-subscriber-form-content {
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 1rem;
    }
    .footer-subscriber-form-content input[type="email"]{
        min-width:200px;
    }
    .footer-subscriber-form-content .subscriber-btn{
        width:200px;
        margin:10px auto;
    }
} */


</style>