<template>
    <div>
        <div  v-if="!useImg">
            <div  class="app-logo" v-if="!isWhite"></div>
            <div  class="app-logo-white" v-if="isWhite"></div>
        </div>
        <router-link class="d-xl-block" :to="{path:'/'}" v-if="useImg && !isWhite">
            <img class="navbar-brand" src="@/assets/img/hsf-theme-logo.png" alt="Housing Solution Fund">
        </router-link>
        <figure v-if="useImg && isWhite">
            <img src="@/assets/img/hsf-default-white-logo.png"
                alt="Housing Solution Funds">
        </figure>
    </div>
    
</template>
<script>
export default {
    name:"AppLogo",
    props:{
        useImg:{default:true},
        isWhite:{default:false}
    },
    data(){
        return{
            
        }
    },
    computed:{
        srcImg(){
            if(this.isWhite){
            return '@/assets/white_logo.png';
            }
            return '@/assets/logo.png';

        }
    }
}
</script>
<style scoped>
    .app-logo{
        background-position: center;
        background-size: cover;
        width:7rem;
        height: 3rem;
        background-repeat: no-repeat;
        background-image: url('../../assets/logo.png');
    }
    .app-logo-white{
        background-position: center;
        background-size: cover;
        width:140px;
        height: 66px;
        background-repeat: no-repeat;
        background-image: url('../../assets/white_logo.png');
    }
</style>