<template>
    <div>
          <div class="mortgage-testimonials-wrapper version-one">
                <article>
                    <div class="row">
                        <div class="col-lg-12">
                            <div id="client-testimonial-carousel" class="carousel slide"
                                data-ride="carousel">
                                <div class="carousel-inner" role="listbox">
                                    <div class="carousel-item active">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <figure>
                                                    <img class="img-fluid"
                                                        src="@/assets/img/sample-client-img.png"
                                                        alt="Housing Fund">
                                                </figure>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="what-they-have-to-say">
                                                    <div class="top-img">
                                                        <img src="@/assets/img/icons/approve.png" alt="" />
                                                    </div>
                                                    <h2>Zero purchase commission</h2>
                                                    <p>
                                                        Searching for a home? get help finding and financing
                                                        your dream home today all commision free on HSF.
                                                    </p>

                                                    <a class="mortgate-test-cta-btn text-white pointer" @click="showAffordabilityModal">
                                                        Take the Affordability Test
                                                    </a>

                                                    <div class="bottom-img">
                                                        <img src="@/assets/img/icons/keys.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <figure>
                                                    <img class="img-fluid" src="@/assets/img/slider-image.png"
                                                        alt="Housing Fund">
                                                </figure>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="what-they-have-to-say">
                                                    <div class="top-img">
                                                        <img src="@/assets/img/icons/approve.png" alt="" />
                                                    </div>
                                                    <h2>Hassle free Mortgage</h2>
                                                    <p>
                                                        Getting home loans just got easy!
                                                        Get prequalified, apply for a mortgage,
                                                        and track your application online, on Housing
                                                        Solution Fund.
                                                    </p>

                                                    <a class="mortgate-test-cta-btn text-white pointer" @click="showAffordabilityModal">
                                                        Take the Affordability Test
                                                    </a>

                                                    <div class="bottom-img">
                                                        <img src="@/assets/img/icons/keys.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="carousel-item">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <figure>
                                                    <img class="img-fluid"
                                                        src="@/assets/img/sample-client-img.png"
                                                        alt="Housing Fund">
                                                </figure>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="what-they-have-to-say">
                                                    <div class="top-img">
                                                        <img src="@/assets/img/icons/approve.png" alt="" />
                                                    </div>
                                                    <h2>Are you a Real Estate Developer?</h2>
                                                    <p>
                                                        Quality lead is Guaranteed!
                                                        Showcase your properties and gain insights
                                                        into the needs of prospective homebuyers.
                                                    </p>

                                                    <a class="mortgate-test-cta-btn text-white pointer" @click="showAffordabilityModal">
                                                        Take the Affordability Test
                                                    </a>

                                                    <div class="bottom-img">
                                                        <img src="@/assets/img/icons/keys.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <ol class="carousel-indicators">
                                    <li data-target="#client-testimonial-carousel" data-slide-to="0"
                                        class="active"></li>
                                    <li data-target="#client-testimonial-carousel" data-slide-to="1"></li>
                                    <li data-target="#client-testimonial-carousel" data-slide-to="2"></li>
                                </ol>
                            </div>
                        </div>




                    </div>
                </article>
            </div>
    </div>
</template>
<script>
import {AppMixin} from '@/mixins/AppMixin'
export default {
    name:"HowItWorkCarousel",
     mixins:[AppMixin],
    // components:{Slider,
    // SliderItem,}
}
</script>
<style  scoped>


</style>