<template>
  <div class="container-fluid p-0">
        <main>
          <!-- <mobile-menu/> -->
          <app-header/>
          
          <router-view class="space-top-for-sticky" ></router-view>
          
          <affordability-modal/>
          <congrat-modal/> 
          <app-footer/>
        </main>
  </div>
</template>
<script>
import AppFooter from '@/components/general/AppFooter.vue'
import AppHeader from '@/components/general/AppHeader.vue'
import AffordabilityModal from '@/modules/calculator/components/AffordabilityModal.vue'
import CongratModal from '@/components/general/CongratModal.vue'
import EventBus from '@/services/event'
import MobileMenu from '@/components/general/MobileMenu.vue'
export default {
  components: { AppHeader, AppFooter,AffordabilityModal,CongratModal,MobileMenu },
  data(){
        return{
            show_menu:false
        }
    },
    methods:{
          closeMenu(){
            // this.show_menu = !this.show_menu;
            EventBus.$emit('show-side-menu',false)
      
        }
    },
    created(){
      EventBus.$on("show-side-menu",(t)=>{
        this.show_menu=t;
        console.log(this.show_menu)
      })
    }
    
}
</script>
<style >
  
  
</style>