import {
    defaulturl
} from '@/services/UrlSetting';
import {
    ApiSource
}
from '@/services/ApiService'
const api = new ApiSource(defaulturl);

const state={
    property_types:'',
    property_status_list:[],
    states:[],
    cities:[],
    lender_rates:[]
}
const mutations={
    UPDATE_STATES(state, payload) {
            state.states = payload
    },
    UPDATE_CITIES(state, payload) {
            state.cities = payload
    },
    PROPERTY_TYPE(state,payload){
        state.property_types = payload
    },
    PROPERTY_STATUS_LIST(state,payload){
        state.property_status_list=payload;
    },
    LENDER_RATE(state,payload){
        state.lender_rates=payload;
    }
}
const actions={
    getLenderRateAction({commit}){
        api.fetchLenderRate().then((res)=>{
            let r = res.data.data;
            commit("LENDER_RATE", r);
        })
    },
      getAllStatesAction({
          commit
      }) {
          api.allStates().then((res) => {
              let y = res.data.data;
              commit("UPDATE_STATES", y);
          });
      },
        getAllCitiesAction({
            commit
        }, state_id) {
            api.allCitiesInState(state_id).then((res) => {
                let y = res.data.data;
                commit("UPDATE_CITIES", y);
            });
        },
    getPropertyTypeAction({commit}){
        api.propertyTypeList().then((res)=>{
            // console.log(res);
            let r = res.data.data;
            commit("PROPERTY_TYPE",r);
        })
    },
    getPropertyStatusAction({commit}){
        api.propertyStatusList().then((res) => {
            // console.log(res);
            let r = res.data.data;
            commit("PROPERTY_STATUS_LIST", r);
        })
    },
}
const getters={}

const general_module = {
    state,
    mutations,
    actions,
    getters
};

export default general_module;