import EventBus from '@/services/event'
import { required, email, minLength, sameAs,requiredIf,numeric } from "vuelidate/lib/validators";
import {
  mapState
} from "vuex";
export const FormMixin ={
    data(){
      return {
  
        resultType: 'Years',
        have_additional: 0,
        have_loan: 0,
        // form:this.$store.state.calculator_module.form
      }
    },
    computed:{
           min() {
               if (this.resultType != '%') {
                 return this.min_range;
               } else {
                 return this.min_range + ' ' + this.resultType;
               }
             },
             max() {
               if (this.resultType != '%') {
                 return !isNaN(this.max_range) && this.max_range > 0 ? this.max_range : 20;
               } else {
                 return this.max_range + ' ' + this.resultType;
               }
             },
    },
    methods: {
       setBubble() {
         const sliderProps = {
           fill: "#6FA73D",
           background: "rgba(214, 216, 231, 1)",
           // background: "rgba(255, 255, 255, 0.214)",
           border_radius: "12px",
           color: "#6FA73D"
         };
         let range = this.$refs.range;
         let bubble = this.$refs.bubble;
         const newValue = Number((range.value - range.min) * 100 / (range.max - range.min));
         let newPosition = 10 - ((newValue * 0.5) + 60);
        //  bubble.innerHTML = ' ' + this.resultType;
         //  bubble.style.left = `calc(${this.tenure}px + (${8 - this.tenure * 0.15}px))`;
         bubble.style.left = `calc(${newValue}% + (${newPosition}px))`;
         const percentage = (100 * (range.value - range.min)) / (range.max - range.min);
         const bg = `linear-gradient(90deg,${sliderProps.fill} ${percentage}%, ${sliderProps.background} ${percentage +
			0.1}%)`;
         range.style.backgroundImage = bg;
         range.style.borderRadius = sliderProps.border_radius;
         //  range.style.color = sliderProps.color;
       },
      calculateAge(date_of_birth) {
          let today = new Date();
          let birthday = new Date(date_of_birth);
          let age = today.getFullYear() - birthday.getFullYear();
          return age;
      },
      calculateMaxTenure(v) {
        let retire_age = 55;
        let age_diff = retire_age - v;
        if (age_diff > 30) {
          return 30;
        }
        return age_diff;
      },
      removeCommaFromNumber(v) {
        var stringValue = v.toString();
          return parseInt(stringValue.replace(/,/g, ""));
      },
      formatToCommaSeperated(val) {
       
           const result = val.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
           return result;
        
       
      },

      currencyFormat(v) {
        if(!isNaN(v)){
        return Number(v).toLocaleString("en");
        }
      },
      resetForm (form) {
          let msg= form+'_errorMessages'
          let err = form+'_hasErrors'
            err = false
            msg =[]
  
          Object.keys(form).forEach(f => {
            this.$refs[f].reset()
          })
        },
      validateForm(form){
        let err = form+'_hasErrors'
        err = false
        Object.keys(form).forEach(f => {
          if (!form[f]) err = true
          this.$refs[f].validate(true)
        })
        // console.log(err)
        if(err){
          return false
        }else{
          return true
        }
      },
      
    }
 }