<template>
 
  <div id="app">

      <!-- <component :is="layout" ></component> -->
     <!-- <div v-if="networkStatus">Offline Status</div> -->
    <!-- <offline @detected-condition="handleConnectivityChange"> -->

        <div slot="online">
          <component :is="layout"></component>
        </div>
        <div slot="offline">
        <!-- <p>You appear to be offline, that's okay, we can still do things..."</p> -->
        </div>
    <!-- </offline> -->
  </div>
</template>

<script>

import offline from "v-offline";
const frontend = "frontend";
export default {
  name: "App",
  components: { offline},
  data(){
      return{
          network_status:true,
            isEditing: false
      }
  },
  computed: {
    layout() {
      return this.network_status?(this.$route.meta.layout || frontend) + "-layout":'offline-layout';
    },
    // networkStatus: function () {
    //     console.log("network status", navigator.onLine)
    //       return navigator.onLine;

    //   }
  },
   methods:{
    preventNav(event) {
        if (!this.isEditing) return
        event.preventDefault()
        event.returnValue = ""
    },
    handleConnectivityChange(status) {
         this.network_status = status
         if(status===false){
               window.addEventListener("beforeunload", this.preventNav)
            this.$once("hook:beforeDestroy", () => {
            window.removeEventListener("beforeunload", this.preventNav);
            })
         }
      console.log('network',status);
     
    },
    fetchGeneralData(){
      this.$store.dispatch("getPropertyTypeAction")
      this.$store.dispatch("getPropertyStatusAction")
      this.$store.dispatch("getAllStatesAction")
    }
    
    //  this.modal = new Modal(this.$refs.exampleModal)

 
   },
   created(){
      this.fetchGeneralData();
   }
}
</script>
<style >
@import './assets/css/main.css';
.pointer{
    text-decoration: none;
    cursor:pointer;
}
.pointer:hover{
    text-decoration: none;
    cursor:pointer;
}
.app-button-color{
  background-color: #528951 !important;
}
.space-top-for-sticky{
  margin-top:3rem;
}
/* range slider */
.range-slider{
  width:100% !important;
}
.slider {
  width: 100%;
  margin-top: 10px;
}

.slider .range-slider-rail {
  height: 11px;
  background-color: #bdbec4;
  border-radius: 5px;
}

.slider .range-slider-fill {
  height: 10px;
  margin: 0 4px;
  background-color: #6FA73D;
}

.slider .range-slider-knob {
  height: 22px;
  width: 22px;
  color: #16465C;
  background-color: #6FA73D;
  border: 0;
  box-shadow: 0 0 0 10px rgb(137 189 161 / 24%)
}

.slider .knobby {
  position: relative;
}

.slider .knobby svg {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -65px;
  left: -14px;
}

.slider .knobby svg text {
  font-size: 5px;
  font-weight: bold;
}
  @media (max-width:1000px){
    .modal-body {
      padding:0.5rem;
    }
  } 
</style>
