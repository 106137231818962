<template>
    <div>
         <section class="featured-properties">
                <div class="featured-properties-tabs-wrapper">
                    <div class="featured-properties-header">
                        <h2>Featured Properties</h2>
                    </div>
                    <div class="featured-properties-tabs">
                        <nav>
                            <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                <a class="nav-item nav-link active" id="nav-property-tab" data-toggle="tab"
                                    href="#nav-property" role="tab" aria-controls="nav-property"
                                    aria-selected="true">Properties</a>
                                <a class="nav-item nav-link" id="nav-plots-tab" data-toggle="tab" href="#nav-plots"
                                    role="tab" aria-controls="nav-plots" aria-selected="false">Serviced Plots</a>
                            </div>
                        </nav>
                    </div>
                </div>
                <div class="overflow-auto mt-3">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-property" role="tabpanel"
                            aria-labelledby="nav-property-tab">
                            <div class="featured-properties-wrapper" >
                                <div class="property-list v2" v-for="p in featuredProperties" :key="p.id">
                                         <new-property-card :isChoose="false" :property="p"/>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade " id="nav-plots" role="tabpanel" aria-labelledby="nav-plots-tab">
                           
                            <div class="featured-properties-wrapper" >
                                  <div class="property-list v2" v-for="p in featuredProperties" :key="p.id">
                                         <new-property-card :isChoose="false" :property="p"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
         </section>

         <!-- <vue-glide>
            <vue-glide-slide></vue-glide-slide>
        </vue-glide> -->
    </div>
</template>
<script>
  import NewPropertyCard from './NewPropertyCard.vue'
export default {
    name:"FeaturedProperties",
     components: { NewPropertyCard},
    data(){
        return{
           
        }
    },
      computed:{
        belowproperties(){
            let p = this.$store.state.properties_module.below_price_properties;
            return p;
        },
        featuredProperties(){
              let p = this.$store.state.properties_module.featured_properties;
            return p;
        },
        recentProperties(){
              let p = this.$store.state.properties_module.recent_properties;
            return p;
        }
    },
    methods:{
         fetchBelowpriceProperties(){
            this.$store.dispatch("belowPricePropertiesAction",15000000)
        },
        fetchFeaturedProperties(){
            this.$store.dispatch("featuredPropertiesAction")
        },
        fetchRecentProperties(){
            this.$store.dispatch("recentPropertiesAction")
        },
    },
    created(){
        this.fetchFeaturedProperties();
    }
}
</script>
<style  scoped>
#nav-property{
    margin-left:3rem;
}
#nav-property::-webkit-scrollbar{
      width: 0;
     height: 0;
     /* Remove scrollbar space */
     background: transparent;
}
.featured-properties-wrapper {
    width: 100%;
    display: flex;
    overflow: scroll;
    flex-wrap: nowrap;
    /* margin-left:15px; */
    /* transform: translateX(60px); */
    
}
.featured-properties-wrapper::-webkit-scrollbar {
     width: 0;
     height: 0;
     /* Remove scrollbar space */
     background: transparent;
}

.property-list-row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
@media (max-width:1000px){
    #nav-property{
    margin-left:0;
}
}
</style>