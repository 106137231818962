import {
    isLoggedIn
} from '@/router/middlewares/guards.js';
import multiguard from 'vue-router-multiguard';
const properties_routes = [
          {path: '/properties/:type',
              name: 'Properties',
              component: () => import( /* webpackChunkName: "pages" */ './views/Properties.vue'),
              meta: {
                  layout: 'frontend'
              }
          },

          {
              path: '/filter-properties',
              name: 'FilterProperties',
              component: () => import( /* webpackChunkName: "pages" */ './views/FilterProperties.vue'),
              meta: {
                  layout: 'frontend'
              }
          }, {
              path: '/property-detail/:slug',
              name: 'PropertyDetail',
              component: () => import( /* webpackChunkName: "pages" */ './views/PropertyDetail.vue'),
              meta: {
                  layout: 'frontend'
              }
          },


];
export default properties_routes;