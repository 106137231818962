<template>
    <div>
         <section class="how-it-work">
                <div class="container">
                    <div class="how-it-work-wrapper">
                        <div class="how-it-work-top-content">
                            <h2>How it Works?</h2>
                        </div>
                        <div class="how-it-works-content">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="how-it-work-child">
                                        <div class="icon-wrapper search">
                                            <img src="@/assets/img/icons/search-circle.png" alt="Search your prefered" />
                                        </div>
                                        <h4>Search your prefered</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A bibendum eget
                                            tellus non sed pretium magna enim. Dignissim et est et in sagittis tortor
                                            nec. Sagittis nullam id imperdiet pulvinar mauris.</p>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="how-it-work-child">
                                        <div class="icon-wrapper wallet">
                                            <img src="@/assets/img/icons/wallet.png" alt="Check Affordabity" />
                                        </div>
                                        <h4>Check Affordabity</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A bibendum eget
                                            tellus non sed pretium magna enim. Dignissim et est et in sagittis tortor
                                            nec. Sagittis nullam id imperdiet pulvinar mauris.</p>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="how-it-work-child">
                                        <div class="icon-wrapper home">
                                            <img src="@/assets/img/icons/home.png" alt="Get your Dream Home" />
                                        </div>
                                        <h4>Get your Dream Home</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A bibendum eget
                                            tellus non sed pretium magna enim. Dignissim et est et in sagittis tortornec. Sagittis nullam id imperdiet pulvinar mauris.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <how-it-work-carousel/>

                       


                    </div>
                </div>
        </section>
    </div>
</template>
<script>
import {AppMixin} from '@/mixins/AppMixin';
import HowItWorkCarousel from './HowItWorkCarousel.vue';
export default {
  components: { HowItWorkCarousel },
    name:"howItWork",
    mixins:[AppMixin]
}
</script>
<style scoped>
 .sample-lady{
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 500px;
        height: 600px;
    }
@media (max-width:1000px){
    .version-one .what-they-have-to-say h2{
        margin-top:2rem;
    }
    .bottom-img {
        padding-top: 2rem;
    }
}
</style>