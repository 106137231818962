<template>
<div >
      <section class="facts-counter">
                <div class="container-fluid">
                    <div class="facts-counter-wrapper">
                        <h4>Our Milestone</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A bibendum eget tellus non sed
                            pretium magna enim. Dignissim et est et in sagittis tortor nec. Sagittis nullam id imperdiet
                            pulvinar mauris.</p>

                        <div class="facts-counter-content">
                            <div class="row">
                                <div class="col-lg-3 col-md-6 col-6 ">
                                    <div class="facts-counter-list">
                                        <div class="facts-counter-text"><span class="counter-text2 count">
                                             <counter :start="0" :end="4"/> 
                                            </span>
                                        </div>
                                    </div>
                                    <p>Pre-qualified Developers</p>
                                </div>
                                <div class="col-lg-3 col-md-6 col-6 ">
                                    <div class="facts-counter-list">
                                        <div class="facts-counter-text"><span class="">
                                            <counter :start="0" :end="1200"/>    
                                        </span>
                                        </div>
                                    </div>
                                    <p>Homes for Sale </p>
                                </div>
                                <div class="col-lg-3 col-md-6 col-6 ">
                                    <div class="facts-counter-list">
                                        <div class="facts-counter-text"><span class="counter-text2 count" >
                                             <counter :start="0" :end="400"/> 
                                            </span>
                                        </div>
                                    </div>
                                    <p>Registered Users</p>
                                </div>
                                <div class="col-lg-3 col-md-6 col-6 ">
                                    <div class="facts-counter-list">
                                        <div class="facts-counter-text"><span class="counter-text2 count">
                                             <counter :start="0" :end="2"/> 
                                            </span></div>
                                    </div>
                                    <p>Prequalified Mortgage Lenders </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
</div>
   
</template>
<script>
import Counter from './Counter.vue'
export default {
  components: { Counter },
    name:"FactCounter",
   

}
</script>
<style  scoped>

    
</style>